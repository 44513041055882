import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, Injector, LOCALE_ID, NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HashLocationStrategy, LocationStrategy, registerLocaleData} from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeEn from '@angular/common/locales/en';
import localeRu from '@angular/common/locales/ru';
import localeEs from '@angular/common/locales/es';
import localeFr from '@angular/common/locales/fr';
import {HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {LoginModule} from './login/login.module';
import {MaterialImportsModule} from '../material-imports.module';
import {UserService} from './services/user.service';
import {HttpService} from './services/http.service';
import {HomeModule} from './home/home.module';
import {StartupService} from './services/startup.service';
import {PageNotFoundModule} from './page-not-found/page-not-found.module';
import {HomeMenuService} from './services/home-menu.service';
import {MobileService} from './services/mobile.service';
import {HeaderModule} from './header/header.module';
import {SearchService} from './services/search.service';
import {StationModule} from './station/station.module';
import {StationService} from './services/station.service';
import {MenuComponent} from './menu/menu.component';
import {TreeComponent} from './tree/tree.component';
import {Permission} from './domain/permission';
import {DynamicFormModule} from './dynamic-form/dynamic-form.module';
import {TranslationService} from './services/translation.service';
import {TranslationPipe} from './pipes/translation.pipe';
import {SharedModule} from '../shared.module';
import {FranchiserModule} from './franchiser/franchiser.module';
import {ErrorStateMatcher, ShowOnDirtyErrorStateMatcher} from '@angular/material/core';
import {CombiBonusCopyComponent} from './station/livesports/combi-bonus-copy/combi-bonus-copy.component';
import {FormsModule} from '@angular/forms';
import {AgGridModule} from 'ag-grid-angular';
import {StationCopyComponent} from './station/station-copy/station-copy.component';
import {NewTabComponent} from './new-tab/new-tab.component';
import {TicketListModule} from './ticket-list/ticket-list.module';
import {TicketDetailModule} from './ticket-detail/ticket-detail.module';
import {RefModule} from './ref/ref.module';
import {WINDOW_PROVIDERS} from './window-provider';
import {SystemConfig} from './config/system-config';
import {LanguageModule} from './language/language.module';
import {TimerComponent} from './timer/timer.component';
import {TextAreaDialogComponent} from './text-area-dialog/text-area-dialog.component';
import {MarkdownModule} from 'ngx-markdown';
import {MarkdownInfoComponent} from './markdown-info/markdown-info.component';
import {NgChartsModule} from 'ng2-charts';

registerLocaleData(localeDe, 'de');
registerLocaleData(localeEn, 'en');
registerLocaleData(localeRu, 'ru');
registerLocaleData(localeFr, 'fr');
registerLocaleData(localeEs, 'es');

export function startupServiceFactory(injector: Injector) {
    return async () => await injector.get(StartupService).tokenLogin();
}

export function localeFactory() {
    const storedLanguage = localStorage.getItem(SystemConfig.LocalStorageConstants.LANGUAGE);
    const localLang = navigator.language.split('-')[0];
    const computedLanguage = ['en', 'de', 'fr', 'ru', 'es'].indexOf(localLang) > -1 ? localLang : 'en';
    console.log('system language: ' + navigator.language + ', computedLanguage: ' + computedLanguage + ', ' + storedLanguage);
    return storedLanguage || computedLanguage;
}

@NgModule({
    declarations: [
        AppComponent,
        MenuComponent,
        TreeComponent,
        StationCopyComponent,
        CombiBonusCopyComponent,
        NewTabComponent,
        TimerComponent,
        TextAreaDialogComponent,
        MarkdownInfoComponent
    ],
    imports: [
        BrowserModule,
        TicketListModule,
        HttpClientModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        TicketDetailModule,
        FormsModule,
        MaterialImportsModule,
        // ServiceWorkerModule.register('./ngsw-worker.js', {enabled: environment.production}),
        AgGridModule,
        LoginModule,
        HomeModule,
        HeaderModule,
        StationModule,
        DynamicFormModule,
        RefModule,
        SharedModule,
        FranchiserModule,
        LanguageModule,
        NgChartsModule,
        MarkdownModule.forRoot({}),
        PageNotFoundModule,
        // must be last
    ],
    providers: [
        HttpService,
        UserService,
        StartupService,
        HomeMenuService,
        MobileService,
        SearchService,
        StationService,
        WINDOW_PROVIDERS,
        TranslationService,
        {
            provide: LOCALE_ID,
            useFactory: localeFactory
        },
        {
            provide: 'permission',
            useValue: Permission
        },
        {
            provide: LocationStrategy,
            useClass: HashLocationStrategy
        },
        {
            provide: APP_INITIALIZER,
            useFactory: startupServiceFactory,
            deps: [Injector],
            multi: true
        },
        {
            provide: ErrorStateMatcher,
            useClass: ShowOnDirtyErrorStateMatcher
        }
    ],
    bootstrap: [AppComponent],
    exports: [TranslationPipe]
})
export class AppModule {
}
